.hospital-card-title {
    p {
        margin-bottom: 0px;
    }

    .left-logo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #3a425a;
        display: inline-block;
        vertical-align: top;

        img {
            max-width: 100%;
            border-radius: 50%;
            height: 100%;
        }
    }

    .right-detail {
        display: inline-block;
        width: calc(100% - 50px);
        padding-left: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        p {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 0px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.detail {
    display: inline-block;
    width: 50%;
    margin-right: -5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 8px !important;
    vertical-align: top;

    i {
        margin-right: 5px;
    }
}

.detail:nth-last-child(-n + 2) {
    margin-bottom: 0px !important;
}

// .detail:last-child {
//     margin-bottom: 0px !important;
// }
.round {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: 10px;
    border-radius: 50%;
}

.topbar {
    .nav-link {
        width: auto !important;
        padding: 8px 5px;
        margin-right: 0px;

        .hospital-name {
            white-space: nowrap;
            width: 112px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block !important;
            vertical-align: middle;
        }

        i {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .btn-rounded {
        border-radius: 50% !important;
        width: 35px;
        padding: 5px !important;
        color: #fff;
        height: 35px;
    }
}

.detail-line {
    width: 100%;
    margin-bottom: 8px;

    label {
        display: inline-block;
        // width: 120px;
        margin-bottom: 0px;
        margin-right: 10px;
    }

    p {
        display: inline-block;
        width: calc(100% - 145px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: top;
        margin-bottom: 0px;
    }
}

// .detail-line:nth-last-child(-n + 3) {
//     margin-bottom: 0px;
// }
.detail-line:last-child {
    margin-bottom: 0px;
}

.small-line {
    width: 33%;
    display: inline-block;
    align-items: flex-start;
}

.full-line {
    width: 100%;
}

.hospital-detail {
    // background-image: url("../../images/bg.png");
    padding: 0px;

    .brand-logo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: top;

        img {
            max-width: 100%;
            height: 60px;
            border-radius: 50%;
        }
    }

    .brand-detail {
        display: inline-block;
        width: calc(100% - 60px);
        margin-right: -5px;
        padding-left: 15px;

        .title {
            h4 {
                margin-bottom: 5px;
            }
        }

        .address-line {

            // margin-top: 10px;
            .icon-box {
                display: inline-block;
                width: 50px;
                font-size: 38px;
                color: #556ee6;
                line-height: 40px;
            }

            .address {
                display: inline-block;
                width: calc(100% - 50px);
                font-size: 12px;
            }
        }
    }

    .legal-no {
        margin-top: 8px;

        .detail-line {
            width: 50%;
            display: inline-block;

            label {
                width: auto;
                margin-right: 10px;
                margin-bottom: 0px;
            }
        }
    }

    .right-side {
        padding: 1rem;
    }
}

.check-box-right {
    position: absolute;
    top: 15px;
    right: 15px;
}

.button-box {
    padding-top: 1.6rem;
}

.add-patient-details {
    position: absolute;
    top: 10px;
    right: 20px;
}

.hospital-name {
    padding: 0px;
}

.left-inner {
    border-radius: 8px;

    .address {
        margin-top: 10px;
    }
}

// @media (max-width: 1139px) {
//     .topbar {
//         .nav-link {
//             width: 100px !important;
//         }
//     }
// }
@media (max-width: 1199px) {
    .topbar .nav-link {
        width: 140px !important;
        padding: 5px;

        .hospital-name {
            width: 70px;
        }
    }

    .brand-detail {
        .title {
            display: inline-block;
            vertical-align: top;
            max-width: 50%;
        }
    }
}

.address-line {
    margin-top: 15px;

    .d1 {
        width: 50%;
        display: inline-block;
        vertical-align: top;

        p {
            display: inline-block;
            margin-bottom: 0px;
        }

        label {
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }

    :last-child {
        text-align: right;
    }
}

@media (max-width: 1439px) {

    // .topbar .nav-link {
    //     width: 100px !important;
    // }
    .navbar-header {
        .d-flex {
            height: 50px;
        }
    }

    .topbar {
        .nav-tabs-custom {
            // margin: 10px 0px;

            // .nav-item {
            //     display: none;
            // }

            .nav-item:first-child {
                display: block;
            }

            .nav-item:nth-child(2) {
                display: block;
            }
        }

        // .header-item {
        //     height: 50px;
        // }
    }
}

.action {
    width: 200px;
    text-align: center;
}

// .scroll-bar-hospital {
//     max-height: calc(100vh - 120px);
//     overflow-y: hidden;
//     scrollbar-width: none;

// }

// .scroll-bar-hospital:hover {
//     overflow-y: auto;
//     scrollbar-width: thin;
// }

.hospital-detail-con {
    height: calc(100vh - 100px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.hospital-card-scroll {
    //height: calc(100vh - 340px);
    height: auto;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.back-btn {
    background: #fff !important;
    border-radius: 8px;
    width: 85px;
    font-weight: 500;
    padding: 11px 15px;
    border: 1px solid #e3e3e3;
    color: #0d6efd;
}

.dark-theme .back-btn{
    background: #020617 !important;
    border-color: #384458;
    overflow: hidden;
    color: #c3cbe4 !important;
    --bs-nav-tabs-border-width: 0px;
}

.dark-theme .page-title-right .back-btn:hover{
    border-color: #fff !important;
    
}

.dark-theme .create-btn-box:hover{
    border-color: #fff !important;
  
}

.create-btn-box {
    position: absolute;
    right: 180px;
    padding: 0;

    li {
        a {
            border: 1px solid #eee;
            background: #fff;
            padding: 8px 15px;
            line-height: 1.9;
        }
    }
}

.only-create-title {
    .create-btn-box {
        right: 90px !important;
    }
}

.serchbar-panel-hospital {
    margin-bottom: 10px;
}

.create-btn-box:hover {
    border-color: #495057 !important;
    color: #495057 !important;
}

.page-title-right .back-btn:hover {
    border-color: #495057 !important;
}

.light-theme .create-btn-box .hospital-name{
    color: #3161c6 !important;
}

.light-theme .create-btn-box{
    color: #0d6efd !important;
}

.dark-theme .create-btn-box .nav-item .nav-link span{
    color: #c3cbe4 !important;
}

.light-theme .create-btn-box.nav-tabs-custom .nav-item .nav-link span { color: #0d6efd !important; }

.light-theme .create-btn-box.nav-tabs-custom .nav-item .nav-link:hover span, .light-theme .create-btn-box.nav-tabs-custom .nav-item .nav-link:hover i { color: #74788D !important; }

.d-sm-block {
  white-space: nowrap;
}

.patient-detail {
  .brand-detail {
    .badge {
      position: absolute;
      right: 45px;
      top: 20px;
    }

    .address-line .d1 {
      width: 32%;
    }
  }

  .address-line .d1 {
    width: 33%;
    display: inline-block;
    vertical-align: top;
  }
}

.step {
  text-align: center;

  li {
    display: inline-block;
    margin-right: 160px;
    position: relative;

    a {
      display: block;
      width: 55px;
      height: 55px;
      background: #2f436c;
      color: #fff;
      border-radius: 50%;
      font-size: 18px;
      padding: 15px;
      font-weight: bold;
    }
  }

  li::after {
    position: absolute;
    left: 100%;
    width: 160px;
    height: 3px;
    background: #2f436c;
    content: "";
    display: block;
    top: 30px;
  }


  li:last-child::after {
    display: none;
  }

  li.active {
    a {
      background: #3d79f7;
    }
  }
}

.sign-box {
  height: 80px;
}

.full-line {
  h4 {
    font-size: 14px;
    color: #3d79f7;
  }
}

.page-title-box {
  .form-control {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    width: 415px;
  }
  select.form-control{
    padding-right: 35px;;
  }
}

.flex-column {
  .nav-item {
    margin-bottom: 8px;
  }

  border-bottom: none;
  border-right: 1px solid #2f436c;
  padding-right: 15px;
  height: 100%;
}

.right-panel {
  background: #2a3042;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.5);
  width: 500px;
  height: calc(100vh - 52px);
  position: fixed;
  top: 50px;
  right: 0;
  overflow-y: auto;-ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 999;
}

.table-responsive {

  // overflow-x: scroll;
  th {
    white-space: nowrap;
  }
}

.right-date {
  position: absolute;
  right: 25px;
  top: 15px;

  .gap-3 {
    display: inline-block;
    margin-left: 20px;
  }
}

.accordion-button {
  padding-left: 50px;
  background-color: #31374a;
  color: #fff;
}

.accordion-button::after {
  position: absolute;
  left: 15px;
}

.accordion-body {
  .small-line {
    label {
      width: 135px;
      display: inline-block;
    }

    p {
      display: inline-block;
    }
  }

  .detail-line {
    width: 100%;
  }
}

.scroll-bar-patients-basic {
  max-height: calc(100vh - 115px);
  overflow-y: auto;
  overflow-x: hidden;-ms-overflow-style: none;
  scrollbar-width: none;
}

.left-box {
  position: absolute;
  left: 0;
  top: 0;
  background: #2f436c;
  color: #fff;
  width: 200px;

  padding: 20px 10px;
  z-index: 3;

  .nav {
    height: calc(100vh - 187px);
    overflow-y: auto;-ms-overflow-style: none;
    scrollbar-width: none;

    li {
      width: 100%;
      display: block;

      a {
        width: 100%;
        display: block;
      }
    }
  }

  .tab-btn {
    width: 40px;
    height: 40px;
    background-color: #2f436c;
    position: absolute;
    left: 100%;
    top: 0px;
    color: #fff;
    text-align: center;
    padding: 8px;
    font-size: 18px;
    border-radius: 0px 8px 8px 0px;
  }

  .close {
    display: inline-block;
  }
}

.right-box {
  padding-left: 200px;


  .tab-title {
    padding-left: 40px;
    color: #3d79f7;
    margin-bottom: 25px;
    font-size: 16px;
  }
}

.admission-card {
  padding: 10px 20px;
}

.close-left {
  .left-box {
    left: -224px;

    .close-btn {
      display: block;
    }

    .open-btn {
      display: none;
    }
  }

  .right-box {
    padding-left: 0px;
  }

  .left-box .tab-btn {
    left: 104%;
  }
}

.profile-collaps {
  position: absolute;
  left: 7px;
  top: 5px;
  text-align: center;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 16px;
}

.add-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.small-card {
  height: 50px;

  .brand-logo {
    width: 35px;
    height: 35px;
    margin-left: 20px;

    img {
      height: 35px;
    }
  }

  .title {
    h4 {
      margin-top: 5px;
    }
  }

  .badge {
    top: 18px !important;
  }
}

.patient-dashboard {
  .d1 {
    text-align: center;

    i {
      font-size: 28px;
      text-align: center;
      color: #fff;
      display: block;
      margin-bottom: 10px;
    }
  }

  .brand-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;

    img {
      height: 100px;
    }
  }

  .left-inner {
    text-align: center;
  }

  .brand-detail {
    .badge {
      font-size: 12px;
    }
  }
}

.contact-detail {
  margin-top: 20px;

  p {
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;

    label {
      width: 50%;
      display: inline-block;
    }
  }

  i {
    margin-right: 8px;
  }

  h5 {
    text-align: left;
  }
}

.address-line {
  margin-top: 15px;
  margin-bottom: 15px;
}

.count-box {
  .line {
    margin-bottom: 10px;

    .img-box {
      display: inline-block;
    }

    .value {
      display: inline-block;
      width: calc(100% - 45px);
      text-align: right;
    }

  }

  h4 {
    font-size: 14px;
  }

  span {
    margin-right: 10px;
  }
}

.medication-list {
  padding-left: 0px;

  li {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 10px;
    position: relative;

    i {
      font-size: 20px;
      display: inline-block;
      margin-right: 15px;
    }

    span {
      position: absolute;
      right: 20px;
      top: 8px;

    }
  }
}

.chart-card {
  height: 315px;
}

table {
  .profile {
    .doctor-img {
      display: inline-block;
      vertical-align: middle;

      img {
        border-radius: 50%;
      }
    }

    .list {
      display: inline-block;
      padding-left: 10px;
      vertical-align: middle;

      p {
        margin-bottom: 0px;
      }
    }
  }

  p {
    margin-bottom: 0px;
  }
}

.report-list {
  padding-left: 0px;

  li {
    list-style: none;
    padding-left: 0;
    border: 1px dashed;
    border-radius: 5px;
    padding: 8px 10px;
    position: relative;
    margin-bottom: 15px;

    i {
      font-size: 22px;
      margin-right: 10px;
      vertical-align: middle;
    }

    a {
      position: absolute;
      right: 15px;
      top: 8px;
    }
  }

  li:last-child {
    margin-bottom: 0px;
  }
}

.fixed-table {
  overflow: hidden;

  table {
    width: 90%;
    overflow-x: scroll;

    tbody {
      th {
        position: sticky;
        left: 0;
      }

      thead {
        th {
          position: sticky;
          top: 0
        }
      }
    }
  }
}

.dashboard-main {
  .card {
    //height: 345px;
    overflow-y: scroll;
    // scrollbar-width: none;
  }

  //.large-card {height: 700px;}

  .chart-card-inner {
    height: 386px;
  }
}

.drop-menu-right {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9;
}

.patient-status {
  span {
    display: inline-block;
    margin-left: 10px;

    img {
      width: 20px;

    }
  }
}

.patient-profile-box{
.overview-main {

  .inner-box {
   
    .inner-con-box{ border-left: 2px dashed #aec2eb;
      padding-left: 15px;
      padding-bottom: 10px;
  }
  }

  h6 {
    position: relative;
    color: #3d79f7;
  }

  h6::after {
    position: absolute;
    left: -20px;
    top: 2px;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    content: "";
    background-color: #3d79f7;
  }

  ul {
    margin-bottom: 0px;
    padding-bottom: 15px;
    list-style: none;
    padding-left: 10px;
  }
}
}
.chart-card-inner {
  padding-top: 60px;

  .card-header-tabs {
    position: absolute;
    right: 20px;
    width: 200px;
    top: 10px;
  }
}

.patient-status.text-left {
  text-align: left;
}

tbody {
  td {
    .patient-card-title {
      width: 80%;
      display: inline-block;
    }

    .patient-status {
      display: inline-block;
      width: 17%;
      margin-top: 15px;
    }
  }
}

.list-card {
  ul {
    padding-left: 0px;

    li {
      list-style: none;
      margin-bottom: 10px;

      span {
        display: inline-block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        font-size: 16px;
        padding: 1px;
        margin-right: 10px;
      }

      .one-color {
        background-color: #f4aeae;
        color: #aa1111;
      }

      .two-color {
        background-color: #aebaf4;
        color: #1111aa;
      }

      .three-color {
        background-color: #b3f4ae;
        color: #11aa20;
      }
    }
  }
}

.admission-card {
  .right-box {
    //height: calc(100vh - 166px);
    overflow: hidden;
  }
}

.patient-dashboard .left-inner {
  .demo {
    position: absolute;
    right: 10px;
    top: 12px;
  }
}

.admission-card{
  .MuiFormControl-root{
    #demo-multiple-checkbox{padding: 0.40rem 0.75rem;}
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #51576b !important;
}
  }
}

.hospital-detail{
  .left-inner{
    height: 100%;
  }
}

.overview-main{
  li {
    b {
  margin-bottom: 5px;
  display: inline-block;
}
  }
  h3{font-size: 16px;}
}
.patient-detail{
  height: 100%;
  .row{height: 100%;}
}

.admission-card{

  .right-box{
    .tab-content{
      .MuiDataGrid-virtualScroller{overflow: scroll !important;overflow-x: hidden !important;-ms-overflow-style: none;
        scrollbar-width: none;
        max-height: calc(100vh - 400px);}
    }
    h3{font-size: 16px;}
    .accordion{height: calc(100vh - 260px);
      overflow: auto;-ms-overflow-style: none;
      scrollbar-width: none;}
      .instruction-panel-accordion{
        .accordion{height: calc(100vh - 410px);}
      }
  }

}

.dashboard-main {
  .general-card-scroll{overflow: unset;
  ul{height: 245px;overflow: auto;-ms-overflow-style: none;
    scrollbar-width: none;}
  &:hover{overflow: unset;}
  }
  .patient-profile-box{overflow-y: scroll;-ms-overflow-style: none;height: 660px;
    scrollbar-width: none;
    &:hover{overflow-x: unset;}
    .overview-main{overflow: auto;-ms-overflow-style: none;
      scrollbar-width: none;
      height: calc(100% - 50px);}
  }
}

.structure-box{
  height: 100%;
  .inner-con-box{height: 100% !important;
  .MuiDataGrid-autoHeight{height: 100% !important;
  .MuiDataGrid-virtualScroller{max-height: calc(100vh - 100px)!important;
  .MuiDataGrid-overlayWrapper{top: 50%;transform: translateY(-50%);}
  }
  }
  }
}
.investigation-box{
  height: 100%;
}

.radiology-card-box{
  .card{
  background:#26446c;margin:15px 15px 0 0; 
  .card-title{color: #fff !important;}
}
}
.table-list{
  td{vertical-align: middle;}
  .left-logo{vertical-align: middle;}
  .right-detail{vertical-align: middle;}
}

.card-dropdown .demo .dropdown-menu-end.dropdown-menu {
  transform: translate(0px, -35px) !important;
  inset: unset !important;
  right: 30px !important;
  top: 40px !important;
}

.max-tab-con {
  width: 100px;
  overflow: hidden;
  .nav-link{
    span{
      width: 80px !important;
    }
    i{
      position: absolute;right: 0;top: 50%;transform: translateY(-50%);
    }
  }
}
.admission-card{
   .right-box{
     .tab-content{
      .hourly-data-box{
       .MuiDataGrid-virtualScroller{max-height: calc(100vh - 320px);}
     }
    }
    }
  }

  .main-table-con{
    .patient-card-box{
    .card-body{height:145px;}
    }
  }

  .patient-admission-card{
    height: calc(100vh - 190px);
    .left-box{
      height: 100%;
      .nav{height: 100%;}
    }
  }

  .instruction-panel-accordion{
    .scroll-box{
      height: 480px;overflow: hidden;
    }
  }
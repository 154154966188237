.report-main {
    .report-top-box {
        .form-control {
            display: inline-block;
            vertical-align: middle;
            width: 100% !important;
            margin-left: 0px !important;
            margin-right: 20px;
        }
    }
    
}


.report-top-box{

.combo-btn-box{
    button{margin-top: 26px;}
}
}
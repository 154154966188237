.login-img {
    img {
        max-width: 150px;
        display: block;
        text-align: center;
        margin: 10px auto;
    }
}

.email-box {
    position: relative;
    .btn {
        position: absolute;
        right: 0;
        top: 27px;
    }
}

.account-pages {
    background-image: url('../../images/background-1.png');
    height: 100vh;
    background-size: cover;
}

.account-pages-footer {
    background: #2a3042;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0px;
    text-align: center;
    padding: 5px 0px;
}
@media screen and (max-width:1919px) {
    .top-box-row {
        .card {
            --bs-card-spacer-y: 15px;
            --bs-card-spacer-x: 15px;

            .dashboard-small-box {
                .avatar-sm {
                    width: 60px !important;
                    height: 60px !important;
                    margin-right: 10px;
                }

                p {
                    font-size: 16px !important;
                }

                .avatar-title {
                    font-size: 30px;
                }
            }
        }

        h5 {
            font-size: 18px;
        }
    }

    .card-common-dashboard {


        .inner-col-box {
            p {
                font-size: 15px;
            }
        }

        .card-title {
            font-size: 17px;
        }
    }

    .max-tab-con {
        width: 80px !important;

        a.nav-link {
            span {
                width: 55px !important;
                text-transform: lowercase;
            }

            i {
                font-size: 13px;
                top: 53%;
                right: 8px;
            }
        }
    }

    .nav-tabs-custom.list-view-tab {
        .nav-item {
            .nav-link {
                padding: 5px 14px 5px 6px;
            }
        }
    }

    .dashboard-main {
        .large-card {
            height: 665px !important;

            .patient-detail {
                .title {
                    h4 {
                        font-size: 16px;
                    }
                }

                .brand-detail {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                .d1 {
                    i {
                        margin-bottom: 3px;
                    }
                }

                .address-line {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }

        .list-card {
            .mt-20 {
                font-size: 20px;
            }
        }
    }

    .scroll-bar-patients-basic {
        h4 {
            font-size: 18px;
        }
    }

    .patient-card-box {
        .patient-box {
            .patient-title-box {
                h4 {
                    font-size: 15px;
                }

                svg {
                    height: 28px;
                    width: 28px;
                }
            }

            p {
                font-size: 13px !important;
            }


            .contact-box {
                padding-bottom: 8px;
            }

            .status-box {
                padding-top: 10px;
                margin-top: 10px;

                .condition-icons-box {
                    svg {
                        height: 24px;
                    }
                }
            }
        }
    }

    .patient-list-view-section {
        .icon-box {
            padding: 6px;

            svg {
                height: 26px;
                width: 26px;
            }
        }

        .admitted-box,
        .discharge-box,
        .not-admitted-box {
            svg {
                width: 18px;
                height: 18px;
            }
        }

        .condition-icons-box {
            svg {
                height: 24px;
            }
        }
    }

    .user-card-box {
        gap: 10px;

        .user-box {
            p {
                font-size: 13px !important;
            }

            .hospital-box {
                padding-top: 10px;
                margin-top: 10px;
                gap: 7px;

                svg {
                    height: 24px;
                }

                h5 {
                    font-size: 13px !important;
                }
            }

            .user-title-box {
                .icon-box {
                    h3 {
                        padding: 7px 14px;
                        font-size: 25px;
                    }
                }

                .user-setting-box {

                    .edit-box,
                    .delete-box {
                        padding: 5px;

                        svg {
                            height: 17px;
                        }
                    }
                }
            }

            .contact-box {
                .common-box {
                    &:first-of-type {
                        padding-bottom: 2px;
                    }
                }
            }
        }
    }
    .user-list-view-section {
        .icon-box {
            h3{font-size: 24px;width: 45px;height: 45px;}
}
.user-setting-box{
     .edit-box, .delete-box {
        svg{height: 17px;}
     }
    }
    }
    .hospital-card-box{
         .user-setting-box {
            .edit-box, .delete-box{padding: 5px;
            svg{height: 17px;}
            }
        }
    }
    .patient-card-box{
         .patient-box {
            .patient-title-box {
                svg {
        height: 25px;
        width: 25px;
         }
        }
        .status-box{
             .discharge-box{
                 svg{height: 16px !important;}
             }
            }
    }
    }
    .card-title {
        font-size: 16px;
    }
    
}

@media screen and (max-width:1599px) {
    .card-common-dashboard {
        height: 270px !important;
        margin-bottom: 10px !important;

        .inner-col-box {
            padding: 18px 15px !important;
        }

        .echarts-for-react {
            height: 180px !important;
        }
    }

    .dashboard-con-scroll {
        .row {
            --bs-gutter-x: 10px;
        }
    }

    .patient-card-box {
        gap: 10px;

        .patient-box {
            .patient-title-box {
                .icon-box {
                    padding: 6px;
                }

                svg {
                    height: 24px;
                    width: 24px;
                }
            }

            .status-box {
                .admitted-box {
                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }

                .condition-icons-box {
                    svg {
                        height: 22px;
                    }
                }
            }

            .address-box {
                height: 60px;
            }
        }

    }

    .nav-tabs-custom .nav-item .nav-link.active,
    .nav-tabs-custom .nav-item .nav-link {
        padding: 6px 10px !important;
    }

    .common-top-con {
        margin-top: 50px;
    }

    .vertical-collpsed {
        .sidebar-bottom {

            .logo-box.small-logo-box {
                img {
                    width: 26px;
                }
            }
        }

    }

    .patient-list-view-section {
        .icon-box {
            svg {
                height: 22px;
                width: 22px;
            }
        }

        .table-list {
            thead {
                th {
                    padding: 10px 16px;
                }
            }
        }

        .right-detail p {
            margin-top: 0;
        }
    }

    .user-card-box {
        .user-box {
            .user-title-box {
                .icon-box {
                    h3 {
                        padding: 5px 11px;
                        font-size: 22px;
                    }
                }

                h4 {
                    font-size: 15px;
                }

                .user-setting-box {

                    .edit-box,
                    .delete-box {
                        svg {
                            height: 18px;
                        }
                    }
                }
            }

            .hospital-box {
                svg {
                    height: 22px;
                }

                h5 {
                    font-size: 13px;
                }
            }
        }

    }

    .user-list-view-section{
        .icon-box {
            h3{height: auto;width: auto;padding: 5px 11px;font-size: 22px;}
        }

        .table-list{
            thead{
                 th {
            padding: 10px 16px;
        }
    }
}
    }
    .hospital-detail-con {
        height: calc(100vh - 135px) !important;
    }
}

@media screen and (max-width:1439px) {

    .top-box-row {
        .card {
            --bs-card-spacer-y: 12px;
            --bs-card-spacer-x: 12px;

            .dashboard-small-box {
                .avatar-sm {
                    width: 50px !important;
                    height: 50px !important;
                }

                p {
                    margin-bottom: 5px !important;
                }
            }
        }
    }

    .page-title-box {
        padding-bottom: 10px;
    }

    .fixed-table-top {
        thead {
            th {
                line-height: 1.3;
            }
        }
    }

    .dashboard-con-scroll {
        height: calc(-120px + 100vh) !important;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .card {
        margin-bottom: 10px;
    }

    .card-common-dashboard {
        height: 265px !important;
    }

    .patient-list-view-section {
        .table-list {
            thead {
                th {
                    padding: 10px 16px !important;
                }
            }

            td {
                p {
                    font-size: 12px !important;
                }
            }
        }
    }

    .dashboard-con-scroll {
        height: calc(-140px + 100vh) !important;
    }
    .patient-card-box{
        .patient-box {
            .patient-title-box{
                 h4{padding-left: 5px;width: 60%;font-size: 14px;}
                 svg{height: 22px;width: 22px;}
            }
        }
    }
}


@media screen and (max-width:1599px) {

    .top-box-row {
        .card {
            .dashboard-small-box {
                .avatar-sm {
                    width: 40px !important;
                    height: 40px !important;
                }

                p {
                    font-size: 14px !important;
                }
            }
        }
    }
}

@media screen and (max-width:1365px) {
    .card-common-dashboard {
        height: 280px !important;
    }

    .top-box-row {
        .card {
            .dashboard-small-box {
                .avatar-sm {
                    height: 35px !important;
                    width: 35px !important;
                }

                .avatar-title {
                    font-size: 24px;
                }

                .me-3 {
                    margin-right: 0 !important;
                }
            }
        }
    }

    .top-box-row {
        .card {
            .dashboard-small-box {
                .avatar-sm {
                    margin-right: 5px !important;
                    height: 30px !important;
                    width: 30px !important;

                    .avatar-title {
                        font-size: 18px;
                    }
                }
            }
        }

        h5 {
            font-size: 18px !important;
        }
    }

    .patient-card-box {

        .patient-box {
            .status-box {
                .discharge-box {
                    padding: 5px 10px;

                    p {
                        font-size: 12px !important;
                    }
                }
            }

            .patient-title-box h4 {
                font-size: 14px;
                padding-left: 8px;
            }
        }
    }


    .user-card-box {
        .user-box {
            .user-title-box {
                .user-setting-box {

                    .edit-box,
                    .delete-box {
                        svg {
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
    .user-list-view-section{
         .user-setting-box{
             .edit-box, .delete-box{padding: 5px;
                svg{height: 16px;}
            }
         }
        }
}

@media screen and (max-width:1279px) {
    .card-common-dashboard {
        height: 265px !important;

        .card-title {
            font-size: 15px;
        }

        .inner-col-box {
            p {
                font-size: 12px;
            }

            h4 {
                font-size: 18px !important;
            }
        }

        .card-body {
            margin: 10px !important;
        }
    }

    .nav-tabs-custom {
        padding: 4px;

        .nav-item {
            .nav-link {
                padding: 5px 15px !important;
            }
        }
    }

    .top-box-row {
        h5 {
            font-size: 15px;
        }
    }

    .vertical-menu {
        width: 185px;

        .navbar-brand-box {
            width: 185px;

            .logo {
                width: 120px !important;
            }


        }
    }

    .main-content {
        margin-left: 185px;
    }

    #page-topbar {
        left: 185px;
    }

    .top-box-row {
        .card {
            .dashboard-small-box {
                .avatar-sm {
                    margin-right: 5px !important;
                    height: 25px !important;
                    width: 25px !important;
                }

                p {
                    font-size: 12px !important;
                }
            }
        }
    }

    .dashboard-con-scroll {
        .row {
            --bs-gutter-x: 6px;
        }
    }

    .patient-card-box {
        grid-template-columns: repeat(3, 1fr);

        .patient-box {
            .status-box {
                .not-admitted-box {
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .user-card-box {
        grid-template-columns: repeat(3, 1fr);
    }

    .right-panel{width: 420px !important;}
    .patient-list-view-section {
        .table-list {
            thead {
                th {
        padding: 7px 12px !important;
    }
}
        }
    }
    .scroll-bar-hospital{
        img{width: 50px;}
        h4{font-size: 16px;}
    }

}

@media screen and (max-width:1023px) {

    .top-box-row {
        .card {
            text-align: center;

            .dashboard-small-box {
                display: block !important;

                .avatar-sm {
                    height: 30px !important;
                    width: 30px !important;
                    margin: 0 auto 5px !important;
                }

                p {
                    margin-bottom: 2px !important;
                }
            }
        }
    }

    .overview-box {
        .col-lg-6 {
            width: 50% !important;
        }
    }

    #page-topbar {
        left: 0 !important;
    }

    .nav-tabs-custom {
        .nav-item {
            .nav-link {
                font-size: 12px;
                padding: 5px 10px !important;
            }
        }
    }

    .page-title-box {
        left: 0 !important;
        padding: 12px 15px !important;

        .form-control {
            width: 250px !important;
        }

        .mr-2 {
            margin-right: 5px;
        }

        .nav-tabs-custom {
            gap: 5px;

            .nav-item {

                .nav-link.active,
                .nav-link {
                    padding: 6px !important;
                }
            }
        }
        .create-btn-box.nav-tabs-custom{height: 35px;
            .nav-item {
                .nav-link{padding: 2px 6px !important;}
        }
    }
}

    .common-top-con {
        margin-top: 45px;
    }

    .patient-card-box {
        grid-template-columns: repeat(2, 1fr);

        .patient-box {
            .patient-title-box {
                .icon-box {
                    padding: 5px;

                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }
            }

            .contact-box {
                padding-bottom: 5px;
            }

            .status-box {
                padding-top: 8px;
                margin-top: 8px;

                .admitted-box,
                .discharge-box {
                    gap: 5px;

                    svg {
                        height: 18px;
                        width: 18px;
                    }

                }
            }

            p {
                font-size: 12px !important;
            }
        }

    }

    .page-title-box {
        padding: 12px 15px !important;

        .MuiFormControl-root {
            min-width: 290px;
        }

    }

    .user-card-box {
        grid-template-columns: repeat(2, 1fr);
    }
    .btn{min-width: 70px;
    padding: 5px !important;
    font-size: 12px !important;}
}


@media screen and (max-width:767px) {
    .top-box-row {
        .col {
            flex: 50%;
        }
    }

    .card-common-dashboard {
        height: 235px !important;
    }

    #sidebar-menu {
        .metismenu {
            height: calc(100vh - 125px);

            li {
                margin-bottom: 0;

                a {
                    padding: 8px 12px;
                }

            }
        }

    }

    .vertical-menu {
        width: 170px;
    }

    .sidebar-bottom img {
        max-width: 100px;
    }

    .page-title-box {
        .form-control {
            margin-left: 0 !important;
        }

        .nav-tabs-custom {
            .nav-item {

                .nav-link.active,
                .nav-link {
                    padding: 5px !important;
                }
            }
        }

        .patient-view-filter {
            .btn {
                padding: 5px;

            }
        }
    }

    .page-title-box {
        .MuiFormControl-root {
            min-width: 230px;

            .MuiOutlinedInput-input {
                font-size: 13px;
            }
        }

        btn {
            margin-right: 10px;
        }
    }
    .right-panel{padding: 10px;
        h4{
            font-size: 18px;
        }
        .tab-content{margin-top: 10px !important;height: calc(100vh - 180px);
            .tab-panel{
                .mb-3{margin-bottom: 6px !important;}
            }
        
        }
        .medium-btn {
            min-width: 70px;
            font-size: 12px !important;
            padding: 5px !important;
        }
        .btn.mr-2{margin-right: 10px;}
    }
    .btn{padding: 5px !important;border-radius: 0.25rem !important;height: 32px;}
    .page-title-right{align-items: center;}
    .hospital-card-box {
        .user-setting-box {
            .edit-box, .delete-box {
                svg {
        height: 14px;
    }
}
        }
    }
    .patient-list-view-section .table-list thead th{font-size: 12px;}
}

@media screen and (max-width:639px) {
    .top-box-row {
        .card {
            margin-bottom: 6px;
        }
    }

    .page-title-box {
        display: flex !important;

        .breadcrumb {
            margin-bottom: 0;
        }
    }

    .card-common-dashboard {
        height: 200px !important;

        .inner-col-box {
            padding: 12px 15px !important;
            margin-bottom: 8px !important;
        }

        .echarts-for-react {
            height: 135px !important;
        }
    }

    .filter-box {
        width: 90%;

        .btn {
            &:first-child {
                margin-right: 5px !important;
            }
        }
    }

    .page-title-box {
        margin-top: 5px;
        display: block !important;

        .nav-tabs-custom {
            .nav-item {

                .nav-link {
                    span {
                        display: block !important;
                    }
                }
            }
        }

        .page-title-right {
            margin-top: 5px;
            display: block !important;

            .patient-view-filter {
                margin-right: 0 !important;
                display: inline-block !important;
            }

            .patient-search-box {
                display: inline-block !important;
            }
        }

        .nav-tabs-custom {
            margin-top: 0;
        }

        .row {
            display: block !important;

            .col-6 {
                width: 100%;
            }
        }

        .MuiFormControl-root {
            min-width: 95%;
        }
    }

    .patient-card-box {
        grid-template-columns: auto;

        .patient-box {
            .address-box {
                height: auto;
            }

        }
    }

    .main-content .page-content {
        padding: 50px 0 0;
    }

    .page-title-right {

        .btn,
        .patient-search-box {
            margin-bottom: 5px;
        }
    }

    .user-card-box {
        margin-top: 130px;
        grid-template-columns: auto;

        .user-box {
            .hospital-box{
                 svg{height: 15px;}
            }
        }
    }
    .user-list-view-section{margin-top: 135px;}
    .right-panel {
        width: 90% !important;
    }
    .nav-tabs-custom {
        .nav-item{
             .nav-link{
                 span{display: block !important;}
             }
            }
        }
        .breadcrumb{padding: 5px !important;margin-bottom: 0 !important;}
        .breadcrumb-item.active {font-size: 14px !important;}
        .navbar-header{
            .address-line{padding-top: 0 !important;}
            .d-flex{align-items: center;}
        }
        .header-profile-user, #page-header-notifications-dropdown{margin-top: -10px;}
        .page-title-box {
            padding: 8px 15px !important;
        }
        .hospital-detail-con{margin-top: 30px;
            .card-body{padding: 15px !important;
              .card-title {
                font-size: 15px;
            }
            }
        
        }
        .mb-3 {
            margin-bottom: 10px !important;
        }
        .hospital-detail-top-con{margin-top: 115px;}
        .right-panel{
            h4{font-size: 15px;}
        }
}


@media screen and (max-width:479px) {
    .container-fluid {
        padding: 10px !important;
    }

    .top-box-row {
        .card {
            --bs-card-spacer-y: 12px;
            --bs-card-spacer-x: 10px;
        }
    }

    .common-top-con.hospital-top-con {
        margin-top: 115px;
    }
.serchbar-panel-hospital{display: block !important;}
}


@media screen and (max-width:359px) {
    .breadcrumb-item.active {
        font-size: 14px;
    }

    .top-box-row {
        .card {
            --bs-card-spacer-x: 8px;

            .dashboard-small-box {
                p {
                    font-size: 11px !important;
                }
            }
        }
    }

    .overview-box {
        .col-lg-6 {
            &:first-child {
                width: 40% !important;
            }

            &:last-child {
                width: 60% !important;
            }
        }
    }
}